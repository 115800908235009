const dayjs = useDayjs()
const checkDiffTime = (params: {
  base?: number
  another?: number
  value?: number
  unit?: any
}) => {
  const { base = dayjs(), another = dayjs(), value = 1, unit = 'd' } = params
  const baseDate = dayjs(base).startOf(unit)
  const anotherDate = dayjs(another).startOf(unit)
  const result = baseDate.diff(anotherDate, unit)

  return result >= value // 단순 날짜 차이 계산
}

function doDownloadClient(callback: Function) {
  const { $customDevice, $toast } = useNuxtApp()
  if ($customDevice.isMobileOrTablet) {
    checkWebPlayPossible(() =>
      $toast.warning('헬로메이플 PC앱은 윈도우 컴퓨터에서만 이용 가능합니다.', {
        toastId: 'download-mo'
      })
    )
  } else {
    window.open(useRuntimeConfig().public.clientDownloadURL, '_blank')
    if (typeof callback === 'function') {
      callback()
    }
  }
}
function checkWebPlayPossible(callback?: Function) {
  const { $customDevice, $toast } = useNuxtApp()
  if ($customDevice.isMobile) {
    $toast.warning('모바일 기기 플레이는 지원하지 않습니다.', {
      toastId: 'play-mo'
    })
    return false
  }
  if (typeof callback === 'function') {
    callback()
  }
}
const downloadBinaryFile = async ({
  blob,
  fileName
}: {
  blob: Blob
  fileName: string
}) => {
  const blobUrl = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = blobUrl
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  // 메모리 누수 방지를 위해 요소/URL 정리
  document.body.removeChild(link)
  URL.revokeObjectURL(blobUrl)
}
export {
  checkDiffTime,
  doDownloadClient,
  checkWebPlayPossible,
  downloadBinaryFile
}
